import React /*,{ Suspense, lazy }*/ from 'react'
import { /*Redirect,*/ Switch } from 'react-router-dom'
import { /*LayoutSplashScreen,*/ ContentRoute } from '../_metronic/layout'

import StartPage from "./pages/start/StartPage";

function BasePage(props) {
  return (
    // <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <ContentRoute path="/" component={StartPage} />
      </Switch>
    // </Suspense>
  )
}

export default BasePage
