import React from 'react'
import {Route} from 'react-router-dom'

import {useKeycloak} from '@react-keycloak/web'

export function PrivateRoute({component: Component, ...rest}) {
    const [keycloak] = useKeycloak()

    if (!keycloak.authenticated) {
        localStorage.clear();
        //window.location.reload();
    } else {
        sessionStorage.setItem('XSRF-TOKEN', keycloak.sessionId);
        sessionStorage.setItem('authentication', keycloak.token);
        sessionStorage.setItem('refreshToken', keycloak.refreshToken);
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                keycloak.authenticated ? (
                    <Component {...props} />
                ) : (
                    keycloak.login()
                )
            }
        />
    )
}
