/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import {BrowserRouter} from "react-router-dom";
// import {PersistGate} from "redux-persist/integration/react";
import {Provider} from "react-redux";
import {AppRouter} from "./routes/index";
import {I18nProvider} from "../_metronic/i18n";
import {ToastProvider} from 'react-toast-notifications';
import {/*LayoutSplashScreen,*/ MaterialThemeProvider} from "../_metronic/layout";
import {store, /*persistor*/} from "../redux/store";
import {KeycloakProvider} from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import {keyCloakConfig, /*base_api_url*/} from '../config/index'

const keycloak = new Keycloak(keyCloakConfig)
const keycloakProviderInitConfig = {
    // "onLoad": "login-required",
    "onLoad": "check-sso",
    "checkLoginIframe": false
}

export default function App({basename}) {
    return (
        // <React.Suspense fallback={<LayoutSplashScreen/>}>
            <Provider store={store}>
                {/*<PersistGate persistor={persistor} loading={<LayoutSplashScreen/>}>*/}
                    <BrowserRouter basename={basename}>
                        <ToastProvider placement={'bottom-right'}>
                            <MaterialThemeProvider>
                                <I18nProvider>
                                    <KeycloakProvider keycloak={keycloak} initConfig={keycloakProviderInitConfig}>
                                        <AppRouter/>
                                    </KeycloakProvider>
                                </I18nProvider>
                            </MaterialThemeProvider>
                        </ToastProvider>
                    </BrowserRouter>
                {/*</PersistGate>*/}
            </Provider>
        // </React.Suspense>
    );
}



