import _ from 'lodash'
const base64 = require('base64url');

export const hasToken=()=>{
    const token = sessionStorage.getItem('authentication')
    if(!_.isEmpty(token)){
        const type = token.split('.')[0]
        const decoded = JSON.parse(base64.decode(type))
        const token_type = decoded.typ
        return token_type === 'JWT'
    }else{
        return false
    }
}

export const getToken = () => {
    return sessionStorage.getItem('authentication')
}
export const getXSRF = () => {
    return sessionStorage.getItem('XSRF-TOKEN')
}
// export const cleanToken=()=>{
//     return sessionStorage.clear()
// }