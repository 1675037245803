import axios from "axios";
import { getToken, getXSRF } from "../utils/checkLogin";
import { merge, isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";

export function request(
  method = "get",
  url,
  new_header = {},
  bodyData = {},
  useXSRF = false
) {
  const header = useXSRF
    ? {
        "X-XSRF-TOKEN": `${getXSRF()}`,
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      }
    : {
        Authorization: `Bearer ${getToken()}`,
      };
  if (isEmpty(getToken())) {
    return {};
  }
  const merged_header = merge(header, new_header);

  if (method === "get") {
    return axios
      .get(url, { headers: merged_header })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  } else if (method === "put") {
    return axios({
      method: "PUT",
      url,
      data: isEmpty(bodyData) ? {} : bodyData,
      headers: merged_header,
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  } else if (method === "post") {
    // check if data is form data
    let isDataEmpty
    if (bodyData instanceof FormData) {
      isDataEmpty = bodyData.entries().next().done
    } else {
      isDataEmpty = isEmpty(bodyData)
    }

    return axios({
      method: "POST",
      url,
      data: isDataEmpty ? {} : bodyData,
      headers: merged_header,
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  } else if (method === "delete") {
    return axios({
      method: "DELETE",
      url,
      data:bodyData,
      headers: merged_header,
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}

export const useAxios = (baseURL) => {
  const [keycloak, initialized] = useKeycloak();
  const [axiosInstance, setAxiosInstance] = useState({});

  useEffect(() => {
    const instance = axios.create({
      baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${keycloak.token}` : undefined,
      },
    });

    setAxiosInstance({ instance });

    return () => {
      setAxiosInstance({});
    };
  }, [baseURL, initialized, keycloak, keycloak.token]);

  return axiosInstance.instance;
};

