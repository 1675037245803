import React, {useLayoutEffect} from "react";
import {KTUtil} from "../../_assets/js/components/util";
import KTLayoutAsideMenu from "../../_assets/js/layout/base/aside-menu";
import KTLayoutContent from "../../_assets/js/layout/base/content";
import KTLayoutFooter from "../../_assets/js/layout/base/footer";
import KTLayoutSubheader from "../../_assets/js/layout/base/subheader";
import KTLayoutStickyCard from "../../_assets/js/layout/base/sticky-card";
import KTLayoutStretchedCard from "../../_assets/js/layout/base/stretched-card";
import KTLayoutHeaderMobile from "../../_assets/js/layout/base/header-mobile";
import { useLocation } from 'react-router-dom'

export function LayoutInit() {
    let location = useLocation();
    useLayoutEffect(() => {
        // Initialization
        KTUtil.ready(function () {
            ////////////////////////////////////////////////////
            // Layout Base Partials(mandatory for core layout)//
            ////////////////////////////////////////////////////

            // Init Mobile Header
            KTLayoutHeaderMobile.init("kt_header_mobile");

            // Init Aside Menu
            KTLayoutAsideMenu.init("kt_aside_menu",location.pathname);

            // Init Content
            KTLayoutContent.init("kt_content");

            // Init Footer
            KTLayoutFooter.init("kt_footer");

            //////////////////////////////////////////////
            // Layout Extended Partials(optional to use)//
            //////////////////////////////////////////////

            // Init subheader
            KTLayoutSubheader.init("kt_subheader",location.pathname);

            // Init Sticky Card
            KTLayoutStickyCard.init("kt_page_sticky_card");

            // Init Stretched Card
            KTLayoutStretchedCard.init("kt_page_stretched_card");

        });
    }, [location]);
    return <></>;
}
