import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import {Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {isEmpty} from "lodash";
import {fetchAccountData} from "../redux/account/actions";
import {base_app_url} from "../config";

function BaseWrapper(props) {
    const [account, setAccount] = useState();
    useMemo(() => {
        const {account, dispatch} = props;
        if (!account.loading) {
            !isEmpty(account.data) && setAccount(account.data);
        }
        if (isEmpty(account.data) && !account.loading) {
            dispatch(fetchAccountData());
        }
    }, [props]);

    if (!isEmpty(account)) {
        if (account.authorities.includes("ROLE_USER")) {
            window.location.href = base_app_url;
        }
    }

    return (
        <div>
            <Layout>
                <BasePage/>
            </Layout>
        </div>
    );
}

const mapStateToProps = (state) => ({
    account: state.account,
    auth: state.auth,
});
export default connect(mapStateToProps)(BaseWrapper);
