import {FETCH_DATA, FETCH_SUCCESS, FETCH_FAIL} from './type'

export function fetchAccountData() {
    return {
        type: FETCH_DATA,
    }
}
export function fetchAccountSuccess(data) {
    return {
        type: FETCH_SUCCESS,
        payload: data
    }
}

export function fetchAccountFail(data) {
    return {
        type: FETCH_FAIL,
        payload: data
    }
}