import {takeLatest, put, call} from 'redux-saga/effects'
import {FETCH_DATA} from './type'
import {request} from '../../utils/hooks';
import {api} from '../../config';
import {isEmpty} from 'lodash'
import {fetchAccountSuccess,fetchAccountFail} from './actions';

function* fetchAccountData() {
    try {
        const account_data = yield call(request,'get',api.getAccount());
        if(!isEmpty(account_data)){
            yield put(fetchAccountSuccess(account_data))
        }
    } catch (error) {
        yield put(fetchAccountFail());
    }
}
export function* root() {
    yield takeLatest(FETCH_DATA, fetchAccountData);
}

export default root;
