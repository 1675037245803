import React, { useMemo, useState} from "react";
import {
    Card,
    CardBody,
    CardFooter,
} from "../../../_metronic/_partials/controls";
import SVG from "react-inlinesvg";
import {Button, Row, Col, Form, Spinner} from "react-bootstrap";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import {Formik, Field, Form as FormikForm} from "formik";
import * as yup from "yup";
import {api, base_app_url, ui} from "../../../config";
import {request} from "../../../utils/hooks";
import {useIntl, FormattedMessage, FormattedHTMLMessage} from "react-intl";
import "rc-time-picker/assets/index.css";
import {useToasts} from "react-toast-notifications";
import Select from "react-select";
import classNames from "classnames";
import {fetchAccountData} from "../../../redux/account/actions";
import Skeleton from "@material-ui/lab/Skeleton";
import {Avatar} from "@material-ui/core";
import {fetchEmployeeData} from "../../../redux/employee/actions";

const mapStateToProps = (state) => ({
    account: state.account,
    employee: state.employee
});

const ConnectedSetupAccountFromChild = connect(mapStateToProps)(
    SetupAccountFromChild
);

export function SetupAccountForm({...props}) {
    return <ConnectedSetupAccountFromChild {...props} />;
}

const roles_options = [
    {value: 0, label: "CEO"},
    {value: 1, label: "Sales"},
    {value: 2, label: "Marketing"},
    {value: 3, label: "Finance"},
    {value: 4, label: "Human Resource"},
    {value: 5, label: "Product Manager"},
    {value: 6, label: "Engineering"},
    {value: 7, label: "Operations"},
    {value: 8, label: "IT"},
    {value: 9, label: "Administration"},
    {value: 10, label: "Customer Success"},
    {value: 11, label: "Other"},
];

function SetupAccountFromChild(props) {

    const intl = useIntl();
    const {addToast} = useToasts();

    const [step, setStep] = useState(0);

    const [account, setAccount] = useState();
    const [ecExist, setEcExist] = useState();
    const [enrollingInCompany, setEnrollingInCompany] = useState(false)



    useMemo(() => {
        const {account, employee, dispatch} = props;
        if (!account.loading) {
            if (!isEmpty(account.data))
            {
                setAccount(account.data);
                let email = account.data.email

                let slackTeamId = account.data.slackTeamId
                if (isEmpty(employee.data) && !employee.loading)
                dispatch(fetchEmployeeData({email},{ slackTeamId}))
            }
        }
        if (isEmpty(account.data) && !account.loading) {
            dispatch(fetchAccountData());
        } else {
            if (!employee.loading) {
                !isEmpty(employee.data) && setEcExist(employee.data);
            }
        }
    }, [props]);



    const companyInformationValidationSchema = yup.object().shape({
        // companyName: yup
        //     .string()
        //     .required("Please enter your team/company title")
        //     ,
        yourRole: yup
            .object()
            .required("Please select your team role.")
            ,
        companySize: yup
            .string()
            .required("Please choose your team/company size."),
        okrExperience: yup
            .string()
            .required("please select how much you know about OKR")

    });

    const handleSetupCompany = async (values) => {
        let companyFormDTO = {
            title: account?.slackTeamName,
            role: (!isEmpty(values.yourRole) ? values.yourRole.value : 0)
        }
        await request(
            "post",
            api.setupCompany(),
            {},
            companyFormDTO,
            false
        )
            .then((response) => {
                addToast(
                    intl.formatMessage({id: "SETUP_FORM_SUBMIT_SUCCESS"}),
                    {
                        appearance: "success",
                        autoDismiss: true,
                    }
                );
                handleAcceptButtonClick();
            })
            .catch((err) => {
                console.log("Error in submit: ", err);
                return addToast(
                    intl.formatMessage({id: "SETUP_FORM_SUBMIT_FAIL"}),
                    {
                        appearance: "error",
                        autoDismiss: true,
                    }
                );
            })
            .finally();
    };

    const handleEnrollInCompany = async () => {

        let teamId = {
            slackTeamId: account?.slackTeamId
        }

        await request(
            "post",
            api.enrollInCompany(),
            {},
            teamId

        )
            .then((response) => {
                addToast(
                    intl.formatMessage({id: "SETUP_FORM_ENROLL_SUCCESS"}),
                    {
                        appearance: "success",
                        autoDismiss: true,
                    }
                );
                setStep(2);
            })
            .catch((err) => {
                console.log("Error in submit: ", err);
                return addToast(
                    intl.formatMessage({id: "SETUP_FORM_ENROLL_FAIL"}),
                    {
                        appearance: "error",
                        autoDismiss: true,
                    }
                );
            })
            .finally( );
    };



    const handleAcceptButtonClick = () => {
        if (step === 0) {
            if ( !isEmpty(ecExist) && ecExist.company && !ecExist.employee) {

                setEnrollingInCompany(true)
                handleEnrollInCompany()
            } else {
                setStep(step + 1);
            }
        }

        if (step === 1) {
            setStep(step + 1);
        }
    };

    const letsGoButtonClick = () => {
        window.location.href = base_app_url;
    };

    // console.log("ecExist", ecExist)



    return (
        <>
            <div>
                <Card className="setup-step__card">
                    {step === 0 && (
                        <>
                            <CardBody
                                className="setup-step__card-body"
                            >
                                {props.account.loading ?
                                    (
                                        <div className="d-flex flex-column">
                                            <Skeleton
                                                animation="wave"
                                                variant="text"
                                                style={{
                                                    width: "70%",
                                                    height: "30rem",
                                                    borderRadius: "0.25rem",
                                                }}
                                                className="align-self-center"
                                            />
                                            <Skeleton
                                                animation="wave"
                                                variant="text"
                                                style={{
                                                    width: "10%",
                                                    height: "2rem",
                                                    borderRadius: "0.25rem",
                                                }}
                                                className="align-self-center"
                                            />
                                            <Skeleton
                                                animation="wave"
                                                variant="text"
                                                style={{
                                                    width: "40%",
                                                    height: "2rem",
                                                    borderRadius: "0.25rem",
                                                }}
                                                className="align-self-center"
                                            />
                                        </div>):
                                    (<div className="start-page__placeholder">
                                        <div className="start-page__placeholder-svg">
                                            <SVG
                                                src={toAbsoluteUrl(
                                                    "/media/svg/humans/start-place-holder.svg"
                                                )}
                                            />
                                        </div>
                                        <div className="start-page__placeholder-text">
                                            <div className="start-page__placeholder-header mt-5">
                                                <FormattedHTMLMessage id="START_PAGE_PLACEHOLDER_HEADLINE"
                                                                      values={{name: (!isEmpty(account) ? account.firstName : "")}}/>
                                            </div>
                                            { !isEmpty(ecExist) && !ecExist.company && !ecExist.employee &&
                                            <div className="start-page__placeholder-exp mt-5">
                                                <FormattedHTMLMessage id="START_PAGE_PLACEHOLDER_EXP"/>
                                            </div>
                                            }
                                            { !isEmpty(ecExist) && ecExist.company && !ecExist.employee &&
                                                <div className="start-page__placeholder-exp mt-5">
                                                    <FormattedHTMLMessage id="START_PAGE_PLACEHOLDER_EXP_ADD_EMPLOYEE" values={{company: account.slackTeamName}}/>
                                                </div>
                                            }
                                            { !isEmpty(ecExist) && ecExist.company && ecExist.employee &&
                                                <div className="start-page__placeholder-exp mt-5">
                                                    <FormattedHTMLMessage id="START_PAGE_PLACEHOLDER_EXP_ALREADY_ADDED" values={{company: account.slackTeamName}}/>
                                                </div>
                                            }
                                        </div>
                                    </div>)
                                }
                            </CardBody>
                            {!(!isEmpty(ecExist) && ecExist.company && ecExist.employee) &&
                                <CardFooter className="custom-card-footer"
                                            style={{justifyContent: "center"}}>
                                    <Button
                                        variant="primary"
                                        onClick={handleAcceptButtonClick}
                                        className="btn btn-primary px-20 font-weight-bold"
                                        disabled={enrollingInCompany || isEmpty(account) }>
                                        {enrollingInCompany &&
                                            <Spinner animation={"border"} size={"sm"} variant={`light-primary}`} className="mr-3 mb-1" />
                                        }
                                        {intl.formatMessage({id: "START_START_BUTTON"})}
                                    </Button>
                                </CardFooter>
                            }
                        </>
                    )}
                    {step === 1 && (
                        <>
                            <Formik
                                initialValues={{
                                    companyName: "",
                                }}
                                validationSchema={companyInformationValidationSchema}
                                onSubmit={handleSetupCompany}
                                validateOnChange={false}
                                validateOnBlur={false}
                            >
                                {({errors, setFieldValue, validateForm, setSubmitting, isSubmitting}) => (
                                    <FormikForm>
                                        <Row className="setup-step__row">
                                            <Col lg={8} md={12} sm={12}>
                                                <CardBody
                                                    className="setup-step__card-body"
                                                >
                                                    <>
                                                        <Row
                                                            className="mt-10 mb-10 ml-1"
                                                        >
                              <span className="svg-icon svg-icon-primary svg-icon-2x">

                                                  <SVG

                                                      title={intl.formatMessage({
                                                          id: "SETUP_ACCOUNT_HEADLINE",
                                                      })}
                                                      src={toAbsoluteUrl("/media/svg/icons/General/Setting-4.svg")}
                                                  /><span className="setup-step__headline ml-2">
                                                            <FormattedMessage id="SETUP_ACCOUNT_HEADLINE"/>
                                                    </span>
                                                </span>

                                                        </Row>
                                                        <Col className="mb-5">
                                                            <div className=" ">
                                                                <span className="d-flex mb-2">
                                                                    <FormattedMessage id="SETUP_COMPANY_NAME_LABEL"/>
                                                                </span>
                                                                <div className="d-inline-flex px-4 py-2 justify-content-center w-100 border border-1 border-light-dark rounded bg-dark-o-25">
                                                                    <Avatar src={account?.slackTeamImage}
                                                                            variant={"rounded"}
                                                                            style={{
                                                                                display:"flex",
                                                                                height: "calc(0.5em + 0.8rem + 2px)",
                                                                                width: "calc(0.5em + 0.8rem + 2px)",
                                                                    }}
                                                                            className="flex-grow-0"
                                                                            sizes="10px"
                                                                    />
                                                                    <span className="flex-grow-1 ml-3 font-size-md font-weight-bold"> {account?.slackTeamName}</span>
                                                                </div>
                                                            </div>

                                                        </Col>
                                                        <Col className="mb-5">
                                                            <Form.Group controlId="yourRole">
                                                                <Form.Label>
                                                                    <FormattedMessage id="SETUP_YOUR_ROLE_LABEL"/>
                                                                </Form.Label>
                                                                <span className="text-danger font-weight-bold">
                                                                  *
                                                                </span>
                                                                <Select
                                                                    name="yourRole"
                                                                    styles={ui.selectionStyle()}
                                                                    options={roles_options}
                                                                    onChange={(opt) => {
                                                                        setFieldValue('yourRole', opt)
                                                                    }}
                                                                    placeholder={intl.formatMessage({
                                                                        id: "SETUP_YOUR_ROLE_PH",
                                                                    })}
                                                                    isMulti={false}
                                                                    className={classNames({
                                                                        'setup-step__input-error': errors.yourRole,
                                                                    })}
                                                                    isSearchable={false}
                                                                />
                                                                <Form.Control.Feedback
                                                                    type="invalid"
                                                                    className={classNames({
                                                                        'setup-step__error-visible': errors.yourRole,
                                                                    })}
                                                                >
                                                                    {errors.yourRole}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col className="mb-5">
                                                            <Form.Group controlId="companySize">
                                                                <Form.Label>
                                                                    <FormattedMessage id="SETUP_COMPANY_SIZE_LABEL"/>
                                                                </Form.Label>
                                                                <span className="text-danger font-weight-bold">
                                                                  *
                                                                </span>
                                                                <Field component="div" name="companySize"
                                                                       className="radio-inline">
                                                                    <label
                                                                        className="radio radio-square mr-10 setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioTwo"
                                                                            name="companySize"
                                                                            value="0"
                                                                        />
                                                                        <span></span>
                                                                        1 - 10
                                                                    </label>
                                                                    <label
                                                                        className="radio radio-square mr-10 setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioOne"
                                                                            name="companySize"
                                                                            value="1"
                                                                        />
                                                                        <span></span>
                                                                        11 - 50
                                                                    </label>
                                                                    <label
                                                                        className="radio radio-square mr-10 setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioOne"
                                                                            name="companySize"
                                                                            value="2"
                                                                        />
                                                                        <span></span>
                                                                        50 - 200
                                                                    </label>
                                                                    <label
                                                                        className="radio radio-square mr-10 setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioOne"
                                                                            name="companySize"
                                                                            value="3"
                                                                        />
                                                                        <span></span>
                                                                        200 and more
                                                                    </label>
                                                                </Field>
                                                                <Form.Control.Feedback
                                                                    type="invalid"
                                                                    className={classNames({
                                                                        'setup-step__error-visible': errors.companySize,
                                                                    })}
                                                                >
                                                                    {errors.companySize}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group controlId="okrExperience">
                                                                <Form.Label>
                                                                    <FormattedMessage id="SETUP_OKR_EXPERIENCE_LABEL"/>
                                                                </Form.Label>
                                                                <span className="text-danger font-weight-bold">
                                                                  *
                                                                </span>

                                                                <Field component="div" name="okrExperience"
                                                                       className="radio-list">
                                                                    <label
                                                                        className="radio radio-square setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioTwo"
                                                                            name="okrExperience"
                                                                            value="0"
                                                                        />
                                                                        <span></span>
                                                                        <FormattedMessage id="SETUP_OKR_EXPERIENCE_OPT_1"/>
                                                                    </label>
                                                                    <label
                                                                        className="radio radio-square setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioOne"
                                                                            name="okrExperience"
                                                                            value="1"
                                                                        />
                                                                        <span></span>
                                                                        <FormattedMessage id="SETUP_OKR_EXPERIENCE_OPT_2"/>
                                                                    </label>
                                                                    <label
                                                                        className="radio radio-square setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioOne"
                                                                            name="okrExperience"
                                                                            value="2"
                                                                        />
                                                                        <span></span>
                                                                        <FormattedMessage id="SETUP_OKR_EXPERIENCE_OPT_3"/>
                                                                    </label>
                                                                    <label
                                                                        className="radio radio-square setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioOne"
                                                                            name="okrExperience"
                                                                            value="3"
                                                                        />
                                                                        <span></span>
                                                                        <FormattedMessage id="SETUP_OKR_EXPERIENCE_OPT_4"/>
                                                                    </label>
                                                                    <label
                                                                        className="radio radio-square setup-step__label">
                                                                        <input
                                                                            type="radio"
                                                                            id="radioOne"
                                                                            name="okrExperience"
                                                                            value="4"
                                                                        />
                                                                        <span></span>
                                                                        <FormattedMessage id="SETUP_OKR_EXPERIENCE_OPT_5"/>
                                                                    </label>
                                                                </Field>

                                                                <Form.Control.Feedback
                                                                    type="invalid"
                                                                    className={classNames({
                                                                        'setup-step__error-visible': errors.okrExperience,
                                                                    })}
                                                                >
                                                                    {errors.okrExperience}
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </>
                                                </CardBody>
                                            </Col>
                                        </Row>
                                        <CardFooter className="custom-card-footer"
                                                    style={{justifyContent: "center"}}>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                className="btn btn-primary px-20"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting &&
                                                    <Spinner animation={"border"} size={"sm"} variant={`light-primary}`} className="mr-3 mb-1" />
                                                }
                                                {intl.formatMessage({id: "START_ACCOUNT_BUTTON"})}
                                            </Button>
                                        </CardFooter>
                                    </FormikForm>
                                )}
                            </Formik>
                        </>
                    )}
                    {step === 2 && (
                        <>
                            <CardBody
                                style={{
                                    backgroundImage: `url(${toAbsoluteUrl("/media/svg/bg/background-celebrate.svg")})`,
                                    backgroundPosition: "top",
                                    backgroundRepeat: "no-repeat",
                                    backgroundImageOpacity: "0.5"
                                }}
                                className="setup-step-final__cardbody"
                            >
                                <Row className="setup-step-final__cardbody__row">
                                    <Col lg={6} md={6} sm={6} className="mx-5">
                                        <Row
                                            style={{
                                                fontSize: "1rem",
                                                fontWeight: "600",
                                                justifyContent: "center",
                                                marginTop: "15rem"

                                            }}
                                        >
                                              <span>
                                                  <SVG
                                                      title={intl.formatMessage({
                                                          id: "SETUP_WELL_DONE_HEADLINE",
                                                      }, {name: (!isEmpty(account) ? account.firstName : "")})}
                                                      style={{fill: "#1BC5BD", width: "28px", height: "28px"}}
                                                      className="svg-icon svg-icon-primary svg-icon-2x mr-2 mb-2"
                                                      src={toAbsoluteUrl("/media/svg/icons/Custom/celebrate-1.svg")}
                                                  /><span className="setup-step-final__headline"
                                                          dangerouslySetInnerHTML={{
                                                              __html: intl.formatMessage({
                                                                  id: "SETUP_WELL_DONE_HEADLINE",
                                                              }, {name: (!isEmpty(account) ? account.firstName : "")})
                                                          }}>
                                                                </span>
                                                </span>
                                        </Row>
                                        <Row
                                            className="setup-step-acc-ready__div"
                                        >
                                              <span>
                                                  <SVG
                                                      title={intl.formatMessage({
                                                          id: "SETUP_WELL_DONE_HEADLINE",
                                                      }, {name: (!isEmpty(account) ? account.firstName : "")})}
                                                      style={{width: "16px", height: "16px"}}
                                                      className="svg-icon svg-icon-md mr-2"
                                                      src={toAbsoluteUrl("/media/svg/icons/Custom/check.svg")}
                                                  />
                                                  <span className="setup-step-acc-ready__headline">
                                                  {intl.formatMessage({
                                                      id: "SETUP_ACC_READY_HEADLINE"
                                                  })}
                                              </span>
                                                </span>
                                            <p className="mt-5">
                                                {intl.formatMessage({
                                                    id: "SETUP_ACC_READY_TEXT"
                                                })}
                                            </p>
                                        </Row>
                                        {!isEmpty(ecExist) && !ecExist.company &&
                                            (<Row
                                                className="setup-step-acc-trial__div"
                                            >
                                                  <span className="setup-step-acc-trial__headline">
                                                  {intl.formatMessage({
                                                      id: "SETUP_TRIAL_HEADLINE"
                                                  })}
                                              </span>
                                                <p className="mt-5">
                                                    {intl.formatMessage({
                                                        id: "SETUP_TRIAL_TEXT"
                                                    })}
                                                </p>

                                            </Row>)
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="custom-card-footer" style={{justifyContent: "center"}}>
                                <Button className="btn btn-primary px-20"
                                        type="submit"
                                        variant="primary"
                                        onClick={letsGoButtonClick}
                                        style={{fontWeight: "600"}}
                                >
                                    {!isEmpty(ecExist) && !ecExist.company ?
                                        intl.formatMessage({id: "LET_S_GO_BUTTON"}) :
                                        intl.formatMessage({id: "LET_S_GO_BUTTON2"})
                                    }
                                </Button>

                            </CardFooter>
                        </>
                    )}
                </Card>
            </div>
        </>
    );

}

