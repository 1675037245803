/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";

import { useHtmlClassService } from "../../_core/MetronicLayout";

import { useIntl } from "react-intl";
import {Link} from "react-router-dom";

function SubHeader() {

  const uiService = useHtmlClassService();

  const intl = useIntl();




  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  return (
    <>
      <div
        id="kt_subheader"
        className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
      >
        <div
          className={`container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {/* begin::Mobile Toggle */}
            {layoutProps.subheaderMobileToggle && (
              <button
                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}
            {/* end::Mobile Toggle */}
          </div>
          {/* Info */}

          {/* Toolbar */}

          <div className="d-flex align-items-center">
            <span
              className="svg-icon svg-icon-lg mx-5"
              style={{ fill: "#FFFFFF" }}
            >
              <Link to={{pathname:"https://ulter.co/support_app"}} target="_blank"><SVG
                  title={intl.formatMessage({ id: "ABOUT" })}
                  src={toAbsoluteUrl("/media/svg/icons/General/Question-circle.svg")}
              /></Link>

            </span>
            <span
                className="svg-icon svg-icon-lg mx-5"
                style={{ fill: "#FFFFFF" }}
            >
                <Link to="/logout"><SVG
                    title={intl.formatMessage({ id: "LOGOUT" })}
                    src={toAbsoluteUrl("/media/svg/icons/General/Sign-out.svg")}
                /></Link>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}


export default SubHeader;
