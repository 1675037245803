import {combineReducers} from 'redux';

import account from '../redux/account/reducer';
import employee from '../redux/employee/reducer';

export default function createReducer() {
    return combineReducers({
        account,
        employee
    });
}
