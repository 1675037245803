import { useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { useHistory } from 'react-router-dom'

const Logout = () => {
  const [keycloak] = useKeycloak()
  const history = useHistory()

  useEffect(() => {
    const handleLogout = async () => {
      try {
        // TODO: fix logout api
        // const res = await request('post', api.logout(), {}, {}, true)
        history.push('/')
        keycloak.logout()
      } catch (e) {
        console.log(e)
      }
    }
    handleLogout()
  }, [history, keycloak])

  return null
}

export default Logout
