import {FETCH_DATA, FETCH_SUCCESS, FETCH_FAIL} from './type'

export function fetchEmployeeData(email, slackTeamId) {
    return {
        type: FETCH_DATA,
        payload: {email, slackTeamId}
    }
}
export function fetchEmployeeSuccess(data) {
    return {
        type: FETCH_SUCCESS,
        payload: data
    }
}

export function fetchEmployeeFail(data) {
    return {
        type: FETCH_FAIL,
        payload: data
    }
}