import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import {
  SetupAccountForm,
} from "../../../_ext/widgets";


const StartPage = () => {

  return (
    <div className="start-page">
      <Container>
        <Row className="setup-step__row">
          <Col sm={12} xl={8}>
            <SetupAccountForm switchToAddForm={true} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StartPage
