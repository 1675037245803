import { getToken } from "../utils/checkLogin";

const env = require("../env-config");

const dev_base_api = "https://api.okr.ulter.me";
const dev_login = "https://auth.okr.ulter.me";
const dev_app = "https://app.okr.ulter.me";

const prod_base_api = "https://api.okr.ulter.me";
const prod_login = "https://auth.okr.ulter.me";
const prod_app = "https://app.okr.ulter.me";

export const base_api_url =
  env.REACT_APP_ENV === "prod" ? prod_base_api : dev_base_api;
export const keyCloakConfig = {
  url: `${env.REACT_APP_ENV === "prod" ? prod_login : dev_login}/auth`,
  realm: "ulter",
  clientId: "cpm",
};

const token = getToken();
export const base_app_url = env.REACT_APP_ENV === "prod" ? prod_app : dev_app;
export const api = {
  baseUrl: base_api_url,
  getAccount: () => `${base_api_url}/api/account`,
  setupCompany: () => `${base_api_url}/api/setup-company`,
  enrollInCompany: () => `${base_api_url}/api/enroll-in-company`,
  existCompanyAndEmployee: (email, slackTeamId) => `${base_api_url}/api/exist-company-employee?email=${email}&slackTeamId=${slackTeamId}`
};

const customStyles = (error = false) => {
  return {
    control: (base, state) => ({
      ...base,
      borderColor: error ? "red" : state.isFocused ? "#00c4b3" : "#E4E6EF",
      boxShadow: "none",
      "&:hover": {
        borderColor: state.isFocused ? "#00c4b3" : "#E4E6EF",
        boxShadow: "none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
      backgroundColor: state.isSelected
        ? "#00c4b3"
        : state.isFocused
        ? "#00b6a6"
        : "white",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isHover ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
    multiValue: (provided) => {
      const styles = {
        fontSize: "1rem",
        backgroundColor: "#fff",
        border: "1px solid #E8E8E8",
        borderRadius: ".3rem",
      };

      return { ...provided, ...styles };
    },
  };
};

const toastConfig = { placement: "bottom-right" };
export const ui = {
  selectionStyle: (error = false) => customStyles(error),
  toast: (target) => toastConfig[target],
};
export const header = {
  Authorization: `Bearer ${token}`,
};
