import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import combineReducer from './reducer';
import rootSaga from './saga';
import logger from 'redux-logger'
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['menu','myEmployee','objectivePage','keyResult']
};

const persistedReducer = persistReducer(persistConfig, combineReducer());
const sagaMiddleware = createSagaMiddleware();

let middlewares = [sagaMiddleware, logger];
if (process.env.NODE_ENV !== 'production')
    middlewares = [sagaMiddleware];


const enhancers = [applyMiddleware(...middlewares)];
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
let store = createStore(persistedReducer, composeEnhancers(...enhancers));
sagaMiddleware.run(rootSaga);
let persistor = persistStore(store);
persistor.purge()
export { store,persistor, sagaMiddleware };