import {takeLatest, put, call} from 'redux-saga/effects'
import {FETCH_DATA} from './type'
import {request} from '../../utils/hooks';
import {api} from '../../config';
import {isEmpty} from 'lodash'
import {fetchEmployeeSuccess,fetchEmployeeFail} from './actions';

function* fetchEmployeeData({payload: {email, slackTeamId}}) {
    try {
        const employee_data = yield call(
            request,
            'get',
            api.existCompanyAndEmployee(email.email, slackTeamId.slackTeamId)
        );
        if(!isEmpty(employee_data)){
            yield put(fetchEmployeeSuccess(employee_data))
        }
    } catch (error) {
        yield put(fetchEmployeeFail());
    }
}
export function* root() {
    yield takeLatest(FETCH_DATA, fetchEmployeeData);
}

export default root;
