import React from "react";
import {toAbsoluteUrl} from "../../_helpers";

export function SplashScreen() {
  return (
    <>
      <div className="splash-screen">
        <img
          src={toAbsoluteUrl("/media/logos/ulter-logo-splash.png")}
          alt="ULTER Logo"
        />
          <img className="splash-spinner2"
               src={toAbsoluteUrl("/media/loading/Pulse-1s-200px.svg")}
               alt="ULTER Logo"
          />
      </div>
    </>
  );
}
