import React/*, { useMemo }*/ from "react";
// import objectPath from "object-path";
// import { useHtmlClassService } from "../../_core/MetronicLayout";

export function Footer() {
  // const uiService = useHtmlClassService();

  // const layoutProps = useMemo(() => {
  //   return {
  //     footerLayout: objectPath.get(uiService.config, "footer.layout"),
  //     footerClasses: uiService.getClasses("footer", true),
  //     footerContainerClasses: uiService.getClasses("footer_container", true),
  //   };
  // }, [uiService]);
  // useEffect(()=>{
  //   const s = document.createElement('script');
  //   s.type = 'text/javascript';
  //   s.async = true;
  //   s.innerHTML = "var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();\n" +
  //       "(function(){\n" +
  //       "var s1=document.createElement(\"script\"),s0=document.getElementsByTagName(\"script\")[0];\n" +
  //       "s1.async=true;\n" +
  //       "s1.src='https://embed.tawk.to/5f4f4849f0e7167d000cbc5f/default';\n" +
  //       "s1.charset='UTF-8';\n" +
  //       "s1.setAttribute('crossorigin','*');\n" +
  //       "s0.parentNode.insertBefore(s1,s0);\n" +
  //       "})();\n";
  //   document.body.appendChild(s);
  // },[]);
  return (
      <>

      </>
  );
}
