import {all} from 'redux-saga/effects';

import Account from './account/saga';
import Employee from './employee/saga';

export default function* AppSaga() {
    yield all([
        Account(),
        Employee()
    ]);
}
