import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";

import App from "./app/App";

import "./sass/index.scss"; // Standard version
// import "./sass/style.react.rtl.css"; // Standard version
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import * as serviceWorker from './serviceWorker'
import {
    MetronicLayoutProvider,
    /*MetronicSplashScreenProvider,*/
    MetronicSubheaderProvider
} from "./_metronic/layout";
import {MetronicI18nProvider} from "./_metronic/i18n";
const {PUBLIC_URL} = process.env;

    //React Render on authentication
    ReactDOM.render(

        <MetronicI18nProvider>
            <MetronicLayoutProvider>
                <MetronicSubheaderProvider>
                    {/*<MetronicSplashScreenProvider>*/}
                        <App basename={PUBLIC_URL}/>
                    {/*</MetronicSplashScreenProvider>*/}
                </MetronicSubheaderProvider>
            </MetronicLayoutProvider>
        </MetronicI18nProvider>
        ,
        document.getElementById("root")
    );
serviceWorker.unregister()
