import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { useKeycloak } from '@react-keycloak/web'

import LogoutPage from '../pages/Logout'
import { PrivateRoute } from './utils'
import BaseWrapper from "../BaseWrapper";
import {SplashScreen} from "../../_metronic/_partials/controls"

export const AppRouter = () => {
  const [, initialized] = useKeycloak()

  if (!initialized) {
    return (
            <SplashScreen />
        )
  }

  return (
    <Router>
      <PrivateRoute path="/" component={BaseWrapper} />
      <Route path="/logout" component={LogoutPage} />
    </Router>
  )
}
