/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {  useMemo , useState} from "react";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Brand } from "../brand/Brand";
import {connect} from "react-redux";
import {isEmpty} from "lodash";
import {fetchAccountData} from "../../../../redux/account/actions";
import {Avatar} from "@material-ui/core";

const mapStateToProps = (state) => ({
    account: state.account
});

const ConnectedAsideChild = connect(mapStateToProps)(
    AsideChild
);

export function Aside({...props}) {
    return <ConnectedAsideChild {...props} />;
}

export function AsideChild(props) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", false),
    };
  }, [uiService]);


    const [account, setAccount] = useState();
    useMemo(() => {
        const {account, dispatch} = props;
        if (!account.loading) {
            !isEmpty(account.data) && setAccount(account.data);
        }
        if (isEmpty(account.data) && !account.loading) {
            dispatch(fetchAccountData());
        }
    }, [props]);


  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
      >
        {/* begin::Primary */}
        <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
          <Brand />
          {/* begin::Nav Wrapper */}
          <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
            {/* begin::Nav */}
            <ul
              className="list-unstyled flex-column align-items-center"
              role="tablist"
            >
              {/* begin::Item */}
              <li
                className="nav-item mb-4 d-flex flex-column align-items-center tour-aside-dashboard-item"
              >
                  <span
                    className={`nav-link btn btn-icon btn-clean btn-lg active` }
                  >
                    <div>
                      <span className="svg-icon svg-icon-lg">
                      </span>
                    </div>
                  </span>
                <div className="text-center flex-grow-1">
                  <span style={{display:"flex",
                    height: "calc(0.5em + 0.35rem)",
                    width: "calc(1.5em + 1.65rem + 2px)",
                    backgroundColor:"#F3F6F9",
                    borderRadius: "0.3rem",
                    marginTop:"0.4rem",
                    borderColor: "transparent"
                  }}>
                  </span>
                </div>
              </li>
              {/* end::Item */}

            </ul>
            {/* end::Nav */}
          </div>
          {/* end::Nav Wrapper */}

          {/* begin::Footer */}
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">

              <span
                    style={{display:"flex",
                      height: "calc(0.75em + 0.75rem + 2px)",
                      width: "calc(0.75em + 0.75rem + 2px)",
                      backgroundColor:"#F3F6F9",
                      borderRadius: "1.42rem",
                      marginTop:"0.4rem",
                      borderColor: "transparent"
                    }} >
            </span>

              { account ?
              <Avatar src={account?.imageUrl}
                      variant={"rounded"}
                      style={{display:"flex",
                          height: "calc(1.0em + 1.65rem + 2px)",
                          width: "calc(1.0em + 1.65rem + 2px)",
                          backgroundColor:"#F3F6F9",
                          borderRadius: "0.42rem",
                          marginTop:"1.4rem",
                          borderColor: "transparent",
                          marginBottom:"1rem",
                          filter: "grayscale(95%)"
                      }}
              /> :
                  <span
                        style={{display:"flex",
                          height: "calc(1.0em + 1.65rem + 2px)",
                          width: "calc(1.0em + 1.65rem + 2px)",
                          backgroundColor:"#F3F6F9",
                          borderRadius: "0.42rem",
                          marginTop:"1.4rem",
                          borderColor: "transparent",
                          marginBottom:"1rem",

                        }} >
                    </span>
              }

          </div>
          {/* end::Footer */}
        </div>
        {/* end::Primary */}

      </div>
      {/* end::Aside */}
    </>
  );
}
